#react-org-chart {
  height: 100%;
  width: 100%;
}


.react-flow__edges svg{
  overflow: visible;
  width: 10000px;
  height: 10000px;
}