.item--container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item--checkbox {
    display: inline-block;
    border-radius: 4px;
    padding: 2px;
}

.item--content {
    margin-left: 4px;
    flex-grow: 1;
    font-size: small;
    margin-top: 2px;
    width: 100%;
    min-width: 0px;
}

.item--delete{
    text-align: right;
    margin-left: 5px;
    cursor: pointer;
}