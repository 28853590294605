 .indicator--container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9ecef;
    border-radius: 5px;
  }
  
  .indicator--circle {
    width: 20px;
    height: 20px;
    background-color: #6c757d; 
    border-radius: 50%;
  }
  