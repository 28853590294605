/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@layer base {
  html {
    @apply text-gray-900;
  }

  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
}

@media print {
  #beacon-container {
    display: none;
  }

  #nprogress {
    display: none;
  }

  .BeaconFabButtonFrame {
    display: none;
  }

  div {
    page-break-inside: avoid;
  }
}

.public-DraftEditor-content .public-DraftStyleDefault-ol {
  padding-left: 20px;
}

.public-DraftEditor-content .public-DraftStyleDefault-ul {
  padding-left: 20px;
}


.org-chart-node-drag.show{
  display:block;
}

.org-chart-node-drag, .activeDrag .org-chart-node-drag{
   display: none;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}


.react-flow__handle {
  opacity: 0;
}

.react-flow__node-custom {
  background-color: rgba(248, 248, 248, 0) !important;
  /* border-radius: 30px ; */
}